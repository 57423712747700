import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiFillGithub, AiFillInstagram } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={12} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I am a
              <i>
                <b className="purple"> SENIOR SOFTWARE ENGINEER </b>
              </i>
              with extensive expertise in financial and various other domains.{" "}
              <br /> <br />
              My technical proficiency spans:
              <br />
              <br />
              <i>
                <b className="purple">Big Data Technologies: </b>
              </i>{" "}
              Spark, Hadoop, Hive, PySpark, Scala, Databricks etc.
              <br />
              <i>
                <b className="purple"> Full Stack Programming: </b>
              </i>{" "}
              Java, Spring Framework, Scala, Python, NodeJS, ReactJS etc.
              <br />
              <i>
                <b className="purple"> Cloud and Containerization: </b>
              </i>{" "}
              AWS, Azure, GCP, Terraform, Docker, Kubernetes, Podman etc.
              <br />
              <i>
                <b className="purple">Database Management: </b>
              </i>{" "}
              PostgreSQL, Oracle SQL, MSSQL, MongoDB, DynamoDB etc.
              <br />
              <i>
                <b className="purple">Trading Skills: </b>
              </i>{" "}
              High-Frequency Trading (HFT) for cash markets and derivatives in
              short medium and long strategies for{" "}
              <i>
                <b className="purple">equity and fixed income</b>
              </i>{" "}
              using{" "}
              <i>
                <b className="purple">quantitative analysis</b>
              </i>
              <br />
              <br />I specialize in building robust, scalable solutions that
              leverage the latest technologies to meet complex business
              challenges. My goal is to help clients achieve optimal outcomes
              through tailored software solutions. Navigate to my{" "}
              <b>
                <i>
                  <a href="/projects" className="purple">
                    projects
                  </a>
                </i>
              </b>{" "}
              for more infos.
              <br />
              <br />
            </p>
          </Col>
          {/* <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>*/}
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME</h1>
            <p>
              Feel free to{" "}
              <b>
                <i>
                  <a href="/contact" className="purple">
                    contact
                  </a>
                </i>
              </b>{" "}
              me <br /> via phone:{" "}
              <a href="tel:+14083170830" className="purple">
                <b>+1 (408) 317-0830</b>
              </a>{" "}
              or email:{" "}
              <a href="mailto:info@4art-it.com" className="purple">
                <b>info@4art-it.com</b>
              </a>
            </p>
            {/*<ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/artem-firsov-a6a915a3/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/firsarte"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://github.com/4art"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
            </ul>*/}
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
