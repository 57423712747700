import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import projects from "../../projects.json"
import { healthCheck } from "../../healthcheck";

function Projects() {
  useEffect(() => {
    healthCheck('projects');
  }, []);
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map(project => (
            <Col md={4} className="project-card">
              <ProjectCard
                imgPath={project.image}
                isBlog={false}
                title={project.title}
                description={project.description}
                technologies={project.technologies}
                position={project.position}
                ghLink="https://github.com/4art"
                demoLink={project.link}
              />
            </Col>))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
