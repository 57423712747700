import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert, Spinner } from 'react-bootstrap';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import api_config from '../api_config.json';
import { healthCheck } from '../healthcheck';


function Contact() {
    useEffect(() => {
        healthCheck('contact');
      }, []);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        comment: ''
    });
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertVariant, setAlertVariant] = useState('danger');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const handlePhoneChange = (value) => {
        setFormData({
            ...formData,
            phone: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidated(true);
        if (e.currentTarget.checkValidity() === false || !isValidPhoneNumber(formData.phone)) {
            e.stopPropagation();
            setShowAlert(true);
            setAlertMessage('Please fill in all required fields correctly.');
            setAlertVariant('danger');
        } else {
            try {
                setIsProcessing(true);
                const response = await fetch(`${api_config.lambda}/contact`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                setIsProcessing(false)
                if (response.ok) {
                    setShowAlert(true);
                    setAlertMessage('Your message has been sent successfully!');
                    setAlertVariant('success');
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        comment: ''
                    });
                    setValidated(false);
                } else {
                    setShowAlert(true);
                    setAlertMessage('There was an error sending your message. Please try again later.');
                    setAlertVariant('danger');
                }
            } catch (error) {
                setShowAlert(true);
                setAlertMessage('There was an error sending your message. Please try again later.');
                setAlertVariant('danger');
            }
        }
    };

    return (
        <Container className="project-section">
            <Container>
                <h1 className="project-heading">Contact</h1>

                {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>}
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col>
                            <Form.Group controlId="name">
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Name*"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    style={{ borderRadius: '5px' }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide your name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="email">
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email*"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    style={{ borderRadius: '5px' }}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid email.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="phone" className="mb-3">
                        <PhoneInput
                            placeholder="Phone*"
                            value={formData.phone}
                            onChange={handlePhoneChange}
                            required
                            defaultCountry="DE"
                            inputComponent={Form.Control}
                            inputProps={{
                                style: { borderRadius: '5px' },
                                required: true
                            }}
                            className={validated && !isValidPhoneNumber(formData.phone) ? 'is-invalid' : ''}
                        />
                        {validated && !isValidPhoneNumber(formData.phone) && (
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid phone number.
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="comment" className="mb-3">
                        <Form.Control
                            required
                            as="textarea"
                            rows={5}
                            placeholder="Comment*"
                            value={formData.comment}
                            onChange={handleInputChange}
                            style={{ borderRadius: '5px' }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please provide a comment.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit" style={{ borderRadius: '5px' }} disabled={isProcessing}>
                        {!isProcessing ? "Send" : "Loading"}
                        {isProcessing ? (
                            <Spinner
                                style={{ width: "0.7rem", height: "0.7rem" }}
                                type="grow"
                                color="light"
                            />
                        ) : null}
                    </Button>
                </Form>
            </Container>
        </Container>
    );
}

export default Contact;
