import axios from 'axios';
import api_config from './api_config';  // Adjust the path as needed

export async function healthCheck(path) {
  try {
    const response = await axios.get(`${api_config.lambda}/healthcheck`, {
      params: { path }
    });
    console.log(response.data); // {status: "OK"}
  } catch (error) {
    console.error('Health check failed', error);
  }
}